<template>
  <a-spin :spinning='spinning'>

    <div ref="goodsManageCreate">
      <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
      <div class="title-mark pl-16">基础信息</div>

      <div v-if="!isConfigOfficialAccount"
           class="mb-8 ml-20 py-8 px-12 text-color-999 border-radius bg-color-f5"
           style="width: fit-content;">
        <svg-icon class="mr-8 text-admin-primary"
                  type="icontishi1" />尚未配置公众号，这会导致客户无法购买商品。
        <router-link class="text-admin-primary cursor-pointer"
                     :to="{name: 'system_setting_official_account'}">
          去配置公众号
        </router-link>
      </div>

      <a-form ref="formRef"
              :model="goods"
              :colon="false"
              :rules="goodsRules"
              :labelCol="{style: 'width: 88px; marginRight: 8px'}">
        <a-form-item label="商品类型"
                     name="type">
          <a-radio-group v-model:value="searchForm.type"
                         @change="clearOriginGoodsList"
                         :disabled="isEdit">
            <a-radio value="normal">普通商品</a-radio>
            <a-radio value="course">网校课程</a-radio>
            <a-radio value="classroom">网校班级</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item v-if="searchForm.type !== 'normal'"
                     label="选择商品">
          <a-button class="text-admin-primary"
                    type="primary"
                    ghost
                    style="width: 176px;"
                    :disabled="isEdit"
                    @click="selectModalVisible = true">
            <PlusOutlined v-if="!isShowGoods" />
            {{isShowGoods ? '重新选择商品' : '点击选择商品'}}
          </a-button>
        </a-form-item>

        <template v-if="isShowGoods">
          <a-form-item label="商品名称"
                       name="title"
                       :wrapperCol="{style: 'width: 320px'}">

            <a-input v-model:value="goods.title"
                     placeholder="请输入商品名称">
              <template #suffix>
                <span class="text-color-999">{{goods.title.length}} / 60</span>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item label="价格"
                       name="price"
                       :wrapperCol="{style: 'width: 200px'}">
            <a-input v-model:value="goods.price"
                     placeholder="请输入商品价格"
                     suffix="元" />
          </a-form-item>

          <a-form-item label="封面"
                       name="cover"
                       validateTrigger="submit">

            <upload-image :image-url="goods.cover"
                          apiName="goods"
                          @child-image="handleImgUrl"
                          desc="建议尺寸750*422或比例16:9，小于2M的JPG、PNG格式图片" />
          </a-form-item>

          <a-form-item label="简介"
                       required
                       :wrapperCol="{span: 10}">
            <div class="editor__container">
              <ckeditor v-model="goods.introduction"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="onReady" />

              <p class="flex justify-between items-start text-color-999">
                <span v-if="!goods.introduction && isIntroError"
                      class="text-right text-danger">请输入商品简介</span>
                <span class="text-right text-danger">{{ textNum > 1000 ? '商品简介最长为1000字' : '' }}</span>
                {{textNum}} / 1000
              </p>
            </div>
          </a-form-item>

          <div class="absolute flex flex-col justify-center"
               style="top: 228px; right: 5%;">
            <div class="mb-28 font-medium text-16 text-center">商品页预览图</div>

            <section class="phone-box">
              <img src="@/assets/phone.png"
                   alt="手机聊天背景图">
              <div class="absolute top-80 left-20 flex justify-between items-center p-8 w-full bg-color-f">
                <LeftOutlined />
                <p class="flex-1 line-clamp-1 text-center">{{ goods.corpName }}</p>
              </div>
              <div class="phone-box__content">
                <a-image class="w-full"
                         style="max-height: 140px;"
                         :src="goods.cover"
                         alt="商品封面" />
                <div class="p-12 text-color-333 font-medium">
                  {{goods.title}}
                </div>

                <p class="h-4 bg-color-f5"></p>

                <div class="p-12 pt-0">
                  <div class="title-mark mt-0 mb-8 pl-16 text-14 ">商品简介</div>
                  <div class="ck-content"
                       v-html="goods.introduction"></div>
                </div>

              </div>
              <div class="absolute left-20 flex w-full h-32 border-top bg-color-f"
                   style="bottom: 74px; line-height: 32px;">
                <div class="flex-1 pl-24 text-left text-color-333">
                  订单金额
                  <span class="ml-4 text-16 text-danger font-medium">¥{{goods.price}}</span>
                </div>
                <div class="w-80 text-center text-color-f bg-red-3">去支付</div>
              </div>
            </section>

          </div>
        </template>

        <div class="save-btn"
             :class="{'not-selected': !isShowGoods}">
          <a-button type="primary"
                    :disabled="!isConfigOfficialAccount"
                    :loading="submitLoading"
                    @click="saveGoods">
            保存
          </a-button>
        </div>
      </a-form>

    </div>
  </a-spin>

  <a-modal v-model:visible="selectModalVisible"
           title="选择商品"
           width="400px"
           :getContainer="() => $refs.goodsManageCreate"
           :footer="null">

    <a-input-search v-model:value="searchForm.title"
                    class="mb-8"
                    :placeholder="selectGoodsPlaceholder"
                    @change="originGoods.loading = true; searchOriginGoodsByTitle()" />

    <ul ref="originGoodsListRef"
        style="height: 240px; overflowY: auto;"
        @scroll="loadMoreOriginGoods">
      <div v-for="goodsItem in originGoods.list"
           :key="goodsItem.id"
           class="flex justify-between items-center py-8 px-16 text-color-333 cursor-pointer hover:bg-opacity-10 hover:bg-admin-primary"
           :class="{'bg-admin-primary bg-opacity-10': goods.goodsId === goodsItem.id}"
           @click="selectGoods(goodsItem)">

        <li v-html="highlight(goodsItem.title, searchForm.title)"
            class="line-clamp-1 w-full" />

        <svg-icon v-if="goods.goodsId === goodsItem.id"
                  class="text-admin-primary"
                  type="iconxuanzhong" />
      </div>

      <a-spin v-show="originGoods.loading"
              class="block text-center" />

      <p v-if="originGoods.finished"
         class="text-center text-color-999">没有更多了~</p>
    </ul>

  </a-modal>

</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import { message, Radio, Image } from "ant-design-vue";
import _ from "lodash";
import { useRouter, useRoute } from "vue-router";

import CKEditor from "@ckeditor/ckeditor5-vue";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn";

import RouterHeader from "@/components/RouterHeader.vue";
import UploadImage from "@/components/UploadImage";
import SvgIcon from "@/components/SvgIcon";
import MyUploadAdapter from "@/views/goodsManage/MyUploadAdapter.js";
import { apiStore } from "@/shared/service/api-client";

import goodsApi from "@/service/api/goods";
import corpSettingApi from "@/service/api/corpSetting";

export default defineComponent({
  name: "GoodsManageCreate",

  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    AImage: Image,

    ckeditor: CKEditor.component,
    RouterHeader,
    UploadImage,
    SvgIcon,
  },

  setup() {
    const GOODS_COVER_DEFAULT =
      "https://ese9a2b9c8d2vx-pub.pubssl.qiqiuyun.net/goods/cover/a5a46fca7f07a867bced4a7f4c76b69b";
    const spinning = ref(true);

    const route = new useRoute();
    const { isEdit } = route.query;

    const routerHeaderInfo = ref([
      {
        path: "/goods/goodsManageIndex",
        name: "商品管理",
      },
      { name: "新建商品" },
    ]);

    const goodsRules = {
      title: [
        {
          required: true,
          whitespace: true,
          message: "请输入商品名称",
        },
        {
          max: 60,
          message: "商品名称长度应为1-60字",
        },
      ],
      price: [
        {
          required: true,
          validator: (rule, value) => {
            if (value === "") {
              return Promise.reject("价格不能为空");
            }
            return Promise.resolve();
          },
        },
        {
          type: "number",
          transform: (value) => Number(value),
          min: 0.01,
          message: "价格不能低于0.01元",
        },
        {
          type: "number",
          transform: (value) => Number(value),
          max: 1000000,
          message: "价格不能高于1000,000元",
        },
      ],
      cover: [
        {
          required: true,
          message: "请上传商品封面图",
        },
      ],
    };

    // 判断是否配置公众号
    const isConfigOfficialAccount = ref(true);

    const getOfficalAccountConfig = async () => {
      const res = await corpSettingApi.getConfigurations();
      isConfigOfficialAccount.value =
        res.setWechatConf?.wechatPlatformStatus == "open";
    };

    // 初始化数据
    async function initData() {
      if (isEdit) {
        routerHeaderInfo.value[1].name = "编辑商品";
        await goodsApi.get({ id: route.query.id }).then((res) => {
          _.assign(goods, res);
          goods.price = _.divide(goods.price, 100);
          searchForm.type = res.type;
        });

        return;
      }

      goods.cover = GOODS_COVER_DEFAULT;
    }

    // 选择直播绑定商品
    const isShowGoods = computed(() => {
      if (searchForm.type === "normal") {
        return true;
      }

      return goods.goodsId;
    });

    const searchForm = reactive({
      originType: "es",
      title: "",
      type: "normal",
      page: 1,
    });

    const selectGoodsPlaceholder = computed(() => {
      return searchForm.type === "course"
        ? "请输入网校课程名称"
        : "请输入网校班级名称";
    });

    const originGoods = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    const initOriginGoods = () => {
      originGoods.list = [];
      originGoods.loading = false;
      originGoods.finished = false;
    };

    async function getGoodsByOrigin() {
      originGoods.loading = true;

      const res = await goodsApi.searchGoodsByOrigin(searchForm);
      searchForm.page++;

      originGoods.list = _.concat(originGoods.list, res.data);
      originGoods.loading = false;

      if (originGoods.list.length >= res.total) {
        originGoods.finished = true;
      }
    }

    const searchOriginGoodsByTitle = _.debounce(() => {
      initOriginGoods();

      searchForm.page = 1;

      getGoodsByOrigin();
    }, 500);

    const originGoodsListRef = ref();

    const loadMoreOriginGoods = _.debounce(() => {
      if (originGoods.finished) {
        return;
      }

      const dom = originGoodsListRef.value;
      if (dom.scrollHeight - dom.scrollTop - dom.clientHeight <= 30) {
        getGoodsByOrigin();
      }
    }, 500);

    function clearOriginGoodsList() {
      if (searchForm.type === "normal") {
        _.assign(goods, {
          title: "",
          cover: "",
          price: "",
          introduction: "",
          goodsId: 0,
        });

        return;
      }

      initOriginGoods();

      searchForm.title = "";
      searchForm.page = 1;

      getGoodsByOrigin();

      _.assign(goods, {
        title: "",
        cover: "",
        price: "",
        introduction: "",
        goodsId: 0,
      });
    }

    // 商品列表弹框
    const selectModalVisible = ref(false);

    function selectGoods(originGoods) {
      const {
        id: goodsId,
        title,
        images: { large: cover },
        specs: [{ price }],
        summary: introduction,
      } = originGoods;

      // goodsId为第三方平台商品的Id
      _.assign(goods, {
        goodsId,
        title,
        cover,
        price,
        introduction,
      });

      selectModalVisible.value = false;
    }

    function highlight(text, keyword) {
      return _.replace(
        text,
        keyword,
        `<span class="text-admin-primary">${keyword}</span>`
      );
    }

    function handleImgUrl(res) {
      goods.cover = res;
      formRef.value.validateFields("cover");
    }

    const goods = reactive({
      goodsId: 0,
      type: searchForm.type,
      title: "",
      cover: "",
      price: "",
      introduction: "",
    });
    const submitLoading = ref(false);

    const router = new useRouter();
    const isIntroError = ref(false);
    const formRef = ref();

    function saveGoods() {
      submitLoading.value = true;
      goods.type = searchForm.type;

      if (searchForm.type !== "normal" && !goods.goodsId) {
        message.error("请选择商品");
        submitLoading.value = false;
        return;
      }

      isIntroError.value = false;

      if (!goods.introduction) {
        isIntroError.value = true;
        submitLoading.value = false;
      }

      formRef.value
        .validate()
        .then(async () => {
          if (isIntroError.value) {
            return;
          }

          isIntroError.value = false;

          if (textNum.value > 1000) {
            submitLoading.value = false;
            return;
          }
          if (isEdit) {
            await goodsApi.edit(goods);
            message.success("编辑成功");
          } else {
            await goodsApi.create(goods);
            message.success("创建成功");
          }

          router.push({ name: "goodsManage_index" });
        })
        .finally(() => {
          submitLoading.value = false;
        });
    }

    const textNum = computed(() => {
      const textContent = _.replace(goods.introduction, /<[^>]*>|/g, "");
      return _.trim(textContent).length;
    });

    getGoodsByOrigin();

    Promise.all([initData(), getOfficalAccountConfig()]).then(() => {
      spinning.value = false;
    });

    onMounted(() => {});
    return {
      apiStore,
      spinning,
      isEdit,
      routerHeaderInfo,
      isConfigOfficialAccount,

      selectGoodsPlaceholder,

      originGoodsListRef,
      loadMoreOriginGoods,
      selectModalVisible,
      searchForm,
      searchOriginGoodsByTitle,
      clearOriginGoodsList,

      originGoods,
      isShowGoods,
      selectGoods,
      highlight,

      handleImgUrl,

      goodsRules,
      goods,
      submitLoading,
      formRef,
      saveGoods,

      textNum,
      isIntroError,
    };
  },

  data() {
    return {
      editor: DecoupledEditor,
      editorConfig: {
        language: "zh-cn", // 中文
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "|",
            "fontColor",
            "fontSize",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "link",
            "imageUpload",
            "imageResize",
            "imageStyle:full",
            "imageStyle:alignLeft",
            "imageStyle:alignRight",
          ],
          shouldNotGroupWhenFull: true,
        },

        fontSize: {
          options: [12, 14, 16, 18, 20],
        },
      },
    };
  },

  methods: {
    onReady(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };

      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  },
});
</script>

<style lang='less' scoped>
:deep(.ant-form-item) {
  margin-bottom: 20px;
}

ul,
li {
  list-style: none;
  padding: 0;
}

.phone-box {
  position: relative;
  width: calc(100% - 43px);

  &__content {
    top: 110px;
    left: 21px;
    width: 100%;
    height: calc(100% - 216px);
    background-color: @bg;
    word-break: break-all;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.editor__container {
  .ck.ck-toolbar,
  .ck.ck-editor__editable_inline {
    border: 1px solid @border;
  }

  .ck-editor__editable {
    height: 300px;
    overflow-y: auto;
  }

  .ck.ck-editor__editable:not(.ck-editor__neste).ck-focused {
    box-shadow: none;
  }
}

.save-btn {
  margin: 0 auto;
  margin-top: 80px;

  &.not-selected {
    position: absolute;
    top: calc(100vh - 246px);
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
}
</style>