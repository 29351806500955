<template>
  <div class="flex flex-col">
    <a-upload class="avatar-uploader"
              :file-list="fileList"
              list-type="picture-card"
              :show-upload-list="false"
              :customRequest="upLoadAvatar"
              :before-upload="beforeUpload"
              @change="handleChange">
      <img v-if="!isUploading && imageUrl"
           class="w-full"
           :src="imageUrl"
           alt="上传图片预览" />
      <div v-else>
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
      </div>
    </a-upload>
    <span class="text-12 text-color-999">{{desc || '建议图片尺寸160*160像素，JPG、PNG格式，图片小于2M'}}</span>
  </div>
</template>

<script>
import _ from "lodash";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import groupSendApi from "@/service/api/groupSend.js";
import corpWordApi from "@/service/api/corpWord";
import businessRadarApi from "@/service/api/businessRadar";
import channelQrcodeApi from "@/service/api/channelQrcode";
import goodsApi from "@/service/api/goods";
import liveApi from "@/service/api/live";

export default {
  name: "UploadImage",
  components: {
    PlusOutlined,
    LoadingOutlined,
  },
  props: {
    imageUrl: String,
    apiName: String,
    desc: String,
  },
  data() {
    const apiList = new Map([
      ["groupSend", groupSendApi],
      ["corpWord", corpWordApi],
      ["businessRadar", businessRadarApi],
      ["channelQrcode", channelQrcodeApi],
      ["goods", goodsApi],
      ["live", liveApi],

      ["groupSendLink", groupSendApi],
      ["corpWordLink", corpWordApi],
      ["businessRadarLink", businessRadarApi],
      ["channelQrcodeLink", channelQrcodeApi],
    ]);

    return {
      fileList: [],
      loading: false,
      apiList,
      isUploading: false,
    };
  },

  computed: {
    Api() {
      return this.apiList.get(this.apiName);
    },
  },

  methods: {
    async upLoadAvatar(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      console.log(file);
      try {
        let res;

        if (
          ["corpWordLink", "businessRadarLink", "channelQrcodeLink"].includes(
            this.apiName
          )
        ) {
          res = await this.Api.uploadLinkCover(formData);
        } else {
          res = await this.Api.uploadImg(formData);
          if (typeof res === "object") {
            res.imageName = file.file.name;
          }
        }

        this.$emit("child-image", res);
        file.onSuccess(res);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$message.error("上传失败！");
        this.loading = false;
      }
    },
    beforeUpload(file) {
      this.fileList = [];
      const index = file.name.lastIndexOf(".");
      const type = file.name.substring(index, file.name.length);

      const isJpgOrPng = [".jpg", ".png", ".jpeg", ".heif", ".heic"].includes(
        _.toLower(type)
      );

      if (!isJpgOrPng) {
        this.$message.error("只能上传jpg或png格式文件！");
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("图片大小必须小于2MB！");
      }

      return isJpgOrPng && isLt2M;
    },
    handleChange({ file, fileList }) {
      if (file.status === "uploading") {
        this.isUploading = true;
        this.fileList = fileList;
        this.loading = true;
        return;
      }

      if (file.status === "done") {
        this.$message.success("上传成功");
        this.loading = false;
      }

      if (file.status === "error") {
        this.loading = false;
        this.$message.error("上传失败");
      }
      this.isUploading = false;
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.ant-upload.ant-upload-select-picture-card) {
  width: 160px;
  height: 90px;
  border: 1px solid @border;
  border-radius: 8px;
  background-color: @bg;

  .anticon-plus {
    font-size: 16px;
    color: #d8d8d8;
  }
}
</style>