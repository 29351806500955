import {apiClient as client} from '@/shared/service/api-client';

export default {
    async search(params) {
        return await client.get('/businessRadar/search', {params: params});
    },
    async get(params) {
      return await client.get('/businessRadar/get', {params: params});
    },
    async create(params) {
      return await client.post('/businessRadar/create', params);
    },
    async update(params) {
      return await client.post('/businessRadar/update', params);
    },
    async delete(params) {
      return await client.post('/businessRadar/delete', params);
    },
    async uploadLinkCover(params) {
      return await client.post('/businessRadar/uploadLinkCover', params);
    },
    async uploadPDF(params) {
        return await client.post('/businessRadar/uploadPDF', params);
    },
    async getUrlBaseInfo(params) {
      return await client.get('/businessRadar/getUrlBaseInfo', {params: params});
    },
    async getShareLink(params) {
        return await client.get('/businessRadar/getShareLink', {params: params});
    },
    async refreshShareLink(params) {
        return await client.get('/businessRadar/refreshShareLink', {params: params});
    },
    async getCountStatistics(params) {
      return await client.get('/businessRadar/getCountStatistics', {params: params});
    },
    async findVisitRecord(params) {
      return await client.get('/businessRadar/findVisitRecord', {params: params});
    },
    async findCustomerVisitHistory(params) {
      return await client.get('/businessRadar/findCustomerVisitHistory', {params: params});
    },
}
