import axios from "axios";
import { apiStore } from "@/shared/service/api-client";

class MyUploadAdapter {
  constructor(loader) {
    // Save Loader instance to update upload progress.
    this.loader = loader;
  }

  async upload() {
    const data = new FormData();
    data.append("typeOption", "upload_image");
    data.append("upload", await this.loader.file);
    data.append("file", await this.loader.file);
    console.log(this.loader.file);

    return new Promise((resolve, reject) => {
      axios({
        url: `/api/console/goods/uploadImage`,
        method: "POST",
        data,
        headers: {
          Authorization: "Bearer " + apiStore.token, // 此处为你定义的token 值(Bearer token 接口认证方式)
        },
      })
        .then((res) => {
          
          const resData = {default: res.data, uploaded: '1'}
          resolve(resData)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default  MyUploadAdapter;
