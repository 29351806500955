import {apiClient as client} from '@/shared/service/api-client';

export default {
    async search(params) {
        return await client.get('/channelQrcode/search', {params: params});
    },
    async create(params) {
        return await client.post('/channelQrcode/create', params);
    },
    async update(params) {
        return await client.post('/channelQrcode/update', params);
    },
    async delete(id) {
        return await client.post('/channelQrcode/delete', {id: id});
    },
    async get(id) {
        return await client.get('/channelQrcode/get', {params: {id: id}});
    },
    // async uploadImg(params) {
    //     return await client.post('/channelQrcode/uploadImg', params);
    // },
    async uploadImg(params) {
        return await client.post('/channelQrcode/uploadTempMedia', params);
    },
    async uploadLinkCover(params) {
        return await client.post('/channelQrcode/uploadLinkCover', params);
    },
    async uploadPDF(params) {
        return await client.post('/channelQrcode/uploadPDF', params);
    },
    async getUrlBaseInfo(params) {
        return await client.get('/channelQrcode/getUrlBaseInfo', {params: params});
    },
    
}
