import {apiClient as client} from '@/shared/service/api-client';

export default {
    async search(params) {
        return await client.get("/goods/search", {params: params});
    },
    async get(params) {
      return await client.get("/goods/get", {params: params});
  },
    async create(params) {
        return await client.post("/goods/create", params);
    },
    async edit(params) {
        return await client.post("/goods/edit", params);
    },
    async delete(params) {
        return await client.post("/goods/delete", params);
    },

    async uploadImg(params) {
      return await client.post("/goods/uploadImage", params);
  },

    async itemUpShelf(params) {
        return await client.post("/goods/itemUpShelf", params);
    },
    async itemDownShelf(params) {
        return await client.post("/goods/itemDownShelf", params);
    },

    async searchGoodsByOrigin(params) {
        return await client.get("/goods/searchGoodsByOrigin", {params: params});
    },

    async getStatisticBaseInfo(params) {
        return await client.get("/goods/getStatisticBaseInfo", {params: params});
    },

    async getStatisticPurchaseRecords(params) {
        return await client.get("/goods/getStatisticPurchaseRecords", {params: params});
    },

    async generateShareUrl(params) {
        return await client.post("/goods/generateShareUrl", params);
    },
}