import {apiClient as client} from '@/shared/service/api-client';

export default {
    async createGroup(params) {
      return await client.post('/corpDialogue/createGroup', params);
    },
    async editGroup(params) {
      return await client.post('/corpDialogue/editGroup', params);
    },
    async deleteGroup(params) {
      return await client.post('/corpDialogue/deleteGroup', params);
    },
    async listGroup(params) {
      return await client.get("/corpDialogue/listGroup", {params: params});
    },
    async listDetailGroup(params) {
      return await client.get("/corpDialogue/listDetailGroup", {params: params});
    },


    async getUrlBaseInfo(params) {
      return await client.get("/corpDialogue/getUrlBaseInfo", {params: params});
    },
    async uploadImg(params) {
      return await client.post('/corpDialogue/uploadTempMedia', params);
    },
    async uploadLinkCover(params) {
      return await client.post('/corpDialogue/uploadLinkCover', params);
    },
    async uploadPDF(params) {
      return await client.post('/corpDialogue/uploadPDF', params);
    },

    async createDialogue(params) {
      return await client.post('/corpDialogue/createDialogue', params);
    },
    async editDialogue(params) {
      return await client.post('/corpDialogue/editDialogue', params);
    },
    async deleteDialogue(params) {
      return await client.post('/corpDialogue/deleteDialogue', params);
    },
    async searchDialogue(params) {
      return await client.get("/corpDialogue/searchDialogue", {params: params});
    },
    async getDialogueDetail(params) {
      return await client.get("/corpDialogue/getDialogueDetail", {params: params});
    },
}
